import { createTheme } from "@mui/material";
const theme = createTheme({
  cssVariables: true,
  colorSchemes: {
    light: {
      palette: {
        primary: {
          main: "#00AFAA",
          dark: "#2F586A",
          light: "#07D0CA",
          contrastText: "#FFFFFF",
          _states: {
            hover: "rgba(5, 131, 127, 0.04)",
            selected: "rgba(5, 131, 127, 0.08)",
            focus: "rgba(5, 131, 127, 0.12)",
            focusVisible: "rgba(5, 131, 127, 0.3)",
            outlinedBorder: "#4F86A0",
            outlinedColor: "#4F86A0",
          },
        },
        secondary: {
          main: "#F8AA13",
          contrastText: "#FFFFFF",
          _states: {
            focusVisible: "rgba(156, 39, 176, 0.3)",
            outlinedBorder: "rgba(156, 39, 176, 0.5)",
          },
        },
        error: {
          main: "#F44771",
          light: "#F44771",
          contrastText: "#FFFFFF",
          indicator: "#E70707",
          _states: {
            focusVisible: "rgba(211, 47, 47, 0.3)",
            outlinedBorder: "rgba(211, 47, 47, 0.5)",
          },
        },
        warning: {
          main: "#FA9703",
          contrastText: "#FFFFFF",
          indicator: "#F2A32B",
          dark: "#E65100",
          _states: {
            focusVisible: "rgba(239, 108, 0, 0.3)",
            outlinedBorder: "rgba(239, 108, 0, 0.5)",
          },
        },
        info: {
          main: "#00AFAA",
          dark: "#08908C",
          light: "#00AFAA",
          contrastText: "#FFFFFF",
          indicator: "#2748FB",
          _states: {
            hover: "rgba(0, 175, 170, 0.04)",
            selected: "rgba(0, 175, 170, 0.08)",
            focusVisible: "rgba(0, 175, 170, 0.3)",
            outlinedBorder: "rgba(0, 175, 170, 0.5)",
          },
        },
        success: {
          main: "#0AC84B",
          light: "#3CE283",
          contrastText: "#FFFFFF",
          indicator: "#0BDB88",
          _states: {
            focusVisible: "rgba(46, 125, 50, 0.3)",
            outlinedBorder: "rgba(46, 125, 50, 0.5)",
          },
        },
        background: {
          default: "#EEF8F8",
          dark: "#244757",
          darker: "#E6E9F9",
          light: "#D0EFED",
        },
        divider: "#C3E4E2",
        elevation: {
          outlined: "#C3E4E2",
        },
        TableCell: {
          border: "#D0EFED",
        },
        _components: {
          alert: {
            error: {
              color: "#FF4757",
              background: "#FFC7CC",
            },
            warning: {
              color: "#F8AA13",
              background: "#FFEBC4",
            },
            success: {
              color: "#0BDB88",
              background: "#C3FFE7",
            },
          },
          switch: {
            knobFillEnabled: "#FAFAFA",
            slideFill: "#000000",
          },
          heading: {
            color: "#022A3A",
          },
        },
        action: {
          disabled: "rgba(0, 0, 0, 0.38)",
          disabledBackground: "rgba(0, 0, 0, 0.12)",
        },
        text: {
          primary: "rgba(0, 0, 0, 0.87)",
          _states: {
            focusVisible: "rgba(0, 0, 0, 0.3)",
          },
        },
        common: {
          white_states: {
            main: "#FFFFFF",
            focusVisible: "rgba(255, 255, 255, 0.3)",
          },
        },
      },
      shadows: [
        "none",
        "0px 4px 4px 0px rgba(0, 0, 0, 0.25",
        "0px 15px 12px 0px rgba(0, 0, 0, 0.10)",
        ...Array(20).fill("none"),
      ],
    },
  },
  shape: {
    borderRadius: 8,
  },
  typography: {
    htmlFontSize: 10,
    fontFamily: "'Inter var', sans-serif",
    body1: {
      fontSize: "1.2rem",
    },
    h1: {
      fontSize: "2.5rem",
      fontWeight: 700,
      color: "var(--mui-palette-_components-heading-color)",
    },
    h2: {
      fontSize: "2rem",
      fontWeight: 700,
      color: "var(--mui-palette-_components-heading-color)",
    },
    h3: {
      fontSize: "1.5rem",
      fontWeight: 700,
      color: "var(--mui-palette-_components-heading-color)",
    },
  },
  components: {
    MuiButton: {
      defaultProps: {
        disableElevation: true,
      },
      styleOverrides: {
        root: {
          fontWeight: 700,
          textTransform: "none",
          letterSpacing: "-0.02rem",
          pt: 0.4,
          pb: 0.4,
          "&.MuiButton-outlinedPrimary": {
            color: "var(--mui-palette-primary-_states-outlinedColor)",
            borderColor: "var(--mui-palette-primary-_states-outlinedBorder)",
          },
        },
      },
    },
    MuiIconButton: {
      defaultProps: {
        size: "small",
      },
      styleOverrides: {
        root: {
          aspectRatio: "1 / 1",
        },
      },
    },
    MuiAppBar: {
      defaultProps: {
        elevation: 0,
      },
    },
    MuiTable: {
      defaultProps: {
        stickyHeader: true,
        size: "small",
      },
    },
    MuiTableHead: {
      styleOverrides: {
        root: ({ theme }) => ({
          backgroundColor: theme.palette.background.light,
          color: theme.palette.background.dark,
        }),
      },
    },
    MuiTableCell: {
      styleOverrides: {
        root: ({ theme }) => ({
          fontSize: "1.2rem",
          variants: [
            {
              props: { variant: "head" },
              style: {
                backgroundColor: theme.palette.background.light,
                color: theme.palette.background.dark,
                top: theme.spacing(8),
              },
            },
          ],
        }),
      },
    },
    MuiCard: {
      defaultProps: {
        variant: "outlined",
      },
    },
    MuiInputBase: {
      defaultProps: {
        size: "small",
      },
      styleOverrides: {
        root: ({ theme }) => ({
          backgroundColor: theme.palette.common.white,
        }),
      },
    },
    MuiTextField: {
      defaultProps: {
        size: "small",
      },
    },
    MuiInputLabel: {
      defaultProps: {
        size: "small",
      },
    },
    MuiOutlinedInput: {
      styleOverrides: {
        root: () => ({
          borderRadius: 3,
        }),
      },
    },
    MuiDialog: {
      defaultProps: {
        fullWidth: true,
      },
    },
    MuiDialogTitle: {
      defaultProps: {
        variant: "h2",
      },
      styleOverrides: {
        root: ({ theme }) => ({
          marginTop: theme.spacing(2),
        }),
      },
    },
    MuiDialogActions: {
      styleOverrides: {
        root: ({ theme }) => ({
          display: "flex",
          justifyContent: "space-between",
          padding: theme.spacing(2),
        }),
      },
    },
  },
});

export default theme;
